import { template as template_1540491525b94a168e8bd26c56b0ee64 } from "@ember/template-compiler";
import icon from "discourse-common/helpers/d-icon";
const InputTip = template_1540491525b94a168e8bd26c56b0ee64(`
  <div
    class="tip {{if @validation.failed 'bad' 'good'}}"
    id={{@id}}
    ...attributes
  >
    {{#if @validation.reason}}
      {{icon (if @validation.failed "xmark" "check")}}
      {{@validation.reason}}
    {{/if}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default InputTip;
