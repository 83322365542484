import { template as template_77bfeed0d1e9478d9cf75e33bf6f5ce0 } from "@ember/template-compiler";
const FKText = template_77bfeed0d1e9478d9cf75e33bf6f5ce0(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
