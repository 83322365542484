import { template as template_6d487b3280c6494282d5665cc48273af } from "@ember/template-compiler";
const FKControlMenuContainer = template_6d487b3280c6494282d5665cc48273af(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
