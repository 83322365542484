import { template as template_23772f4af3ef40c58eacd9b2f0a42fc1 } from "@ember/template-compiler";
const FKLabel = template_23772f4af3ef40c58eacd9b2f0a42fc1(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
