import { template as template_beb4ba3fdafb41fbb24588ec7943525a } from "@ember/template-compiler";
const WelcomeHeader = template_beb4ba3fdafb41fbb24588ec7943525a(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
